import { useTranslation } from 'react-i18next';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../../components/app/routerLinks';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import walletEmpty from '../../../assets/imgs/icons/wallet-empty.png';
import './UserWalletPage.scss';
import UserWalletModal from './UserWalletModal';

const UserWalletPage = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [modalOpened, setModalOpened] = useState(false);

  const sharedTrans = (key) => t(`userDashboard.${key}`);
  return (
    <div className="user-wallet-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: userRouterLinks?.userDashboard
          },
          {
            title: t('walletPage.title'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-arrow-wrap">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            {i18n.dir() === 'rtl' ? (
              <img src={HrArrowRtl} alt="back" />
            ) : (
              <img src={HrArrowLtr} alt="back" />
            )}
          </button>
          <div className="main-page-title">{t('walletPage.title')}</div>
        </div>
        <div className="page-body">
          <div className="wallet-balance">
            <div className="wallet-title">{t('walletPage.balance')}</div>
            <div className="wallet-value">
              <span>{user?.wallet}</span> <span>{t('currency.sar')}</span>
            </div>

            <button
              className="charge-btn"
              onClick={() => {
                setModalOpened(true);
              }}
            >
              <img src={walletEmpty} alt="Empty" />
              {t('walletPage.chargeWallet')}
            </button>
          </div>
        </div>
      </div>

      {modalOpened && (
        <UserWalletModal
          modalOpened={modalOpened}
          setModalOpened={setModalOpened}
        />
      )}
    </div>
  );
};

export default UserWalletPage;
