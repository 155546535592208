import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import ChangePasswordIcon from '../../../common/icons/user-icons/ChangePasswordIcon';
import StatisticsIcon from '../../../common/icons/user-icons/StatisticsIcon';
// import ChatCenterIcon from '../../../common/icons/user-icons/ChatCenterIcon';
import CubeIcon from '../../../common/icons/CubeIcon';
import RemoveUserIcon from '../../../common/icons/RemoveUserIcon';
import StoresIcon from '../../../common/icons/StoresIcon';
import MyAddressIcon from '../../../common/icons/user-icons/MyAddressIcon';
import MyOrdersIcon from '../../../common/icons/user-icons/MyOrdersIcon';
import MyWalletIcon from '../../../common/icons/user-icons/MyWalletIcon';
import SettingsIcon from '../../../common/icons/user-icons/SettingsIcon';
import routerLinks from '../../../components/app/routerLinks';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';
import CloseUserAccountModal from './CloseUserAccountModal';
import UserDashboardCard from './UserDashboardCard';
import './UserDashboardPage.scss';

const UserDashboardPage = () => {
  const { t } = useTranslation();
  const labelTransString = (key) => t(`userDashboard.${key}`);
  const [closeAccountModalOpened, setCloseAccountModalOpened] =
    React.useState(false);

  const dashboardCards = [
    {
      id: 1,
      title: t('main_app_bar_links.user_saloons'),
      to: userRouterLinks?.userSaloonsRoute,
      icon: <StoresIcon />
    },
    {
      id: 2,
      title: labelTransString('settings'),
      to: userRouterLinks?.accountSettings,
      icon: <SettingsIcon />
    },
    {
      id: 3,
      title: labelTransString('changePass'),
      to: userRouterLinks?.changePassword,
      icon: <ChangePasswordIcon />
    },
    {
      id: 4,
      title: labelTransString('bankAccount'),
      to: userRouterLinks?.bankAccount,
      icon: <CubeIcon />
    },
    {
      id: 5,
      title: labelTransString('myOrders'),
      to: userRouterLinks?.myOrders,
      icon: <MyOrdersIcon />
    },
    {
      id: 5,
      title: labelTransString('address'),
      to: userRouterLinks?.myAddresses,
      icon: <MyAddressIcon />
    },
    // {
    //   id: 6,
    //   title: labelTransString('chatCenter'),
    //   to: userRouterLinks?.userChatCenterRoute,
    //   icon: <ChatCenterIcon />
    // },
    {
      id: 7,
      title: labelTransString('myWallet'),
      to: userRouterLinks?.myWallet,
      icon: <MyWalletIcon />
    },
    {
      id: 8,
      title: labelTransString('statistics'),
      to: userRouterLinks?.statistics,
      icon: <StatisticsIcon />
    },
    {
      id: 'close_account_id',
      title: labelTransString('closeAccount'),
      to: null,
      icon: <RemoveUserIcon />
    }
  ];

  const renderDashboardCards = () => {
    return (
      <section className="dashboard-cards-wrapper">
        {dashboardCards.map((card) => {
          return (
            <UserDashboardCard
              setCloseAccountModalOpened={setCloseAccountModalOpened}
              closeAccountModalOpened={closeAccountModalOpened}
              key={card?.id}
              {...card}
            />
          );
        })}
      </section>
    );
  };

  return (
    <div className="user-dashboard-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: t('userDashboard.home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: t('userDashboard.dashboard'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-arrow-wrap">
          <p className="main-page-title">{t('userDashboard.dashboard')}</p>
        </div>
        {dashboardCards?.length > 0 && renderDashboardCards()}
      </div>

      {closeAccountModalOpened && (
        <CloseUserAccountModal
          modalOpened={closeAccountModalOpened}
          setModalOpened={setCloseAccountModalOpened}
        />
      )}
    </div>
  );
};

export default UserDashboardPage;
